import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {WeeklyOrdersPlacedByStatsRow} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';


export const WeeklyOrdersPlacedByTile: React.FC = () => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyOrdersPlacedByStatsRow; }; }>(
        ['weeklyOrdersPlacedByStats'],
        () => ApiClient.Sm.Report.weeklyOrdersPlacedByStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    return (
        <>
            {Object.keys(data).reverse().slice(0,1).map((year) => {
                return (
                    <>
                        {Object.keys(data[year]).reverse().filter((v, i) => {
                            return i == 0
                        }).map((week) => {
                            const weekNumber: number = +week;

                            return (
                                <>
                                    <div key={week} className="card mb-5 mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className="px-9 pt-7 card-rounded h-175px w-100 bg-success">
                                                <div className="d-flex flex-stack text-white"><h3
                                                    className="m-0 fw-bold text-white fs-3">Orders door klanten
                                                    week {week}</h3>
                                                    <span>
                                                        <span
                                                            className="fw-bold fs-2x pt-1 ">{data[year][week].percentageKlanten || 0} 	</span>
                                                        %</span>
                                                </div>
                                            </div>
                                            {data[year][weekNumber - 1] &&
                                            <div
                                                className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                                                style={{marginTop: '-100px'}}>
                                                {data[year][weekNumber - 1] &&
                                                <div className="d-flex align-items-center mb-6">
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber - 1}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber - 1]?.percentageKlanten || 0} %
                                                            </div>
                                                            <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {data[year][weekNumber - 2] &&
                                                <div className="d-flex align-items-center mb-6">

                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber - 2}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber - 2]?.percentageKlanten || 0} %
                                                            </div>
                                                            <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {data[year][weekNumber - 3] &&
                                                <div className="d-flex align-items-center mb-6">

                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber - 3}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber - 3]?.percentageKlanten || 0} %
                                                            </div>
                                                            <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {data[year][weekNumber - 4] &&
                                                <div className="d-flex align-items-center mb-0">

                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber - 4}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber - 4]?.percentageKlanten || 0} %
                                                            </div>
                                                            <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            }

                                        </div>
                                    </div>
                                </>
                            )
                        })
                        }

                    </>
                )

            })}
        </>
    );
};